<template>
  <div>
    <el-card>
      <el-row :gutter="20" class="left">
				<el-col :span="24">
					<span style="color:#666">订单列表</span>
				</el-col>
			</el-row>
			<el-divider></el-divider>
      <el-form class="left margin_left" :inline="true" :model="queryForm" ref="reference">
        <el-row>
          <el-col :span="24">
            <el-form-item label="服务名称" prop="serviceName">
              <el-input
                v-model="queryForm.serviceName"
                placeholder="请输入服务名称"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="技师姓名" prop="techName">
              <el-input
                v-model="queryForm.techName"
                placeholder="请输入技师姓名"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="系统订单号" prop="orderNumber">
              <el-input
                v-model="queryForm.orderNumber"
                placeholder="请输入系统订单号"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="下单手机号" prop="orderPhone">
              <el-input
                v-model="queryForm.orderPhone"
                placeholder="请输入下单时填写的手机号"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="日期" prop="datePicker">
              <el-date-picker
                v-model="queryForm.datePicker"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="状态" prop="options">
              <el-select v-model="queryForm.options" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        
        <el-form-item>
          <el-button type="primary" @click="search" icon="el-icon-search"
            >搜索</el-button
          >
          <el-button type="default" @click="reset" icon="el-icon-refresh"
            >重置</el-button
          >
        </el-form-item>
      </el-form>

      <div class="left margin_left">
        <el-button type="primary" size="mini" plain icon="el-icon-printer" @click="orderPrint">打印</el-button>
        <el-button type="primary" size="mini" plain icon="el-icon-download" @click="excel">导出</el-button>
      </div>

      <el-table
        id="orderListTable"
        class="margin_top margin_left"
        :data="orderListTable"
        style="width: 100%"
        :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
        <el-table-column fixed prop="order_id" label="ID" width="100"></el-table-column>
        <el-table-column label="服务项目信息" width="280">
          <template slot-scope="scope">
            <div class="service">
              <div class="service_img"><img :src="scope.row.service_info.service_logo" alt=""></div>
              <div class="service_info">
                <div class="info">
                  <div>{{scope.row.service_info.service_name}}</div>
                  <div v-show="scope.row.order_status==4" class="refundCount">已退</div>
                </div>
                <div class="price_count">
                  <div class="price">￥{{scope.row.service_info.service_price}}</div>
                  <div class="count">x{{scope.row.service_info.service_number}}</div>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="consignee" label="下单人" width="120"></el-table-column>
        <el-table-column prop="mobile" label="下单手机号" width="120"></el-table-column>
        <el-table-column prop="jishi_name" label="技师" width="120"></el-table-column>
        <el-table-column prop="service_start_time" label="服务开始时间" width="180"></el-table-column>
        <el-table-column prop="car_str" label="出行费用" width="180"></el-table-column>
        <el-table-column label="服务项目费用" width="120">
          <template slot-scope="scope">
            <div>￥{{scope.row.service_fee}}</div>
          </template>
        </el-table-column>
        <el-table-column label="实收金额" width="120">
          <template slot-scope="scope">
            <div>￥{{scope.row.pay_fee}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="order_sn" label="系统订单号" width="200"> </el-table-column>
        <el-table-column prop="add_time" label="下单时间" width="200"> </el-table-column>
        <el-table-column prop="order_status_name" label="状态" width="100"> </el-table-column>
        <el-table-column fixed="right" label="操作" width="100" class-name="last_hidden">
          <template slot-scope="scope">
            <div class="last_hiddens">
              <el-button  size="mini" type="primary" plain @click="goDetail(scope.row.order_id)">查看</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
    </el-card>
  </div>
</template>

<script>
let that;
let timer = null;
import FileSaver from "file-saver";
import {printExcel,excelExport} from "@/api/utils";
import Pagination from '@/components/Pagination/Pagination.vue'
export default {
  components:{
    Pagination
  },
  data () {
    return {
      queryInfo: {
        query: "",
        pagenum: 1,
        pagesize: 10,
      },
      total:0,
      queryForm:{
        serviceName:'',
        techName:'',
        orderNumber:'',
        orderPhone:'',
        datePicker:'',
        options:''
      },
      options: [
        { value: "0", label: "全部订单" },
        { value: "1", label: "已取消" },
        { value: "2", label: "待支付" },
        { value: "3", label: "待服务" },
        { value: "4", label: "技师接单" },
        { value: "5", label: "技师出发" },
        { value: "6", label: "技师到达" },
        { value: "7", label: "服务中" },
        { value: "8", label: "已完成" }
      ],
      orderListTable:[]
    }
  },
  created() {
  	if(!this.$store.state.userInfo) {
			// 防止未登录
			this.$router.push({
				path: '/login'
			})
		} else {
			that = this;
			this.getOrderList();
		}
  },
  methods: {
  	getOrderList(){
  		var url = 'order/order_list';
			let params = {
				service_name: this.queryForm.serviceName,
				jishi_name: this.queryForm.techName,
				order_sn: this.queryForm.orderNumber,
				mobile: this.queryForm.orderPhone,
				date_time: this.queryForm.datePicker,
				order_status: this.queryForm.options,
				size: this.queryInfo.pagesize,
				page: this.queryInfo.pagenum,
			};
			this.fd_post(url, params).then((res) => {
				if(res.status) {
					that.total = res.total;
					that.orderListTable = res.list;
					console.log(res.list);
				} else {
					that.$message.error(res.msg);
				}
			}).catch((err) => {
				that.$message.error('网络错误');
			});
  	},
    //页数改变
    handleSizeChange(newSize) {
      this.queryInfo.pagenum=1
      this.queryInfo.pagesize=newSize
      this.getOrderList();
    },
    //页码改变
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum=newPage
      this.getOrderList();
    },
    //搜索
    search() {
			this.queryInfo.pagenum=1
    	this.getOrderList();
    },
    //重置
    reset() {
      this.$refs["reference"].resetFields();
      this.getOrderList();
    },
    //打印
    orderPrint(){
      printExcel('orderListTable')
    },
    //导出
    excel(){
      excelExport('orderListTable')
    },
    //查看
    goDetail(pkid){
      this.$router.push({
        path:'/order_list/order_detail',
        query:{
          pkid
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import './Order_list.scss';
</style>